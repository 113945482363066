<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2022-06-20 15:39:50
-->
<template>
	<div class="industryInformation">
		<div v-if="showWrit" style="width: 120px;" @click="handleForm('add')" class="industryInformation_btn">
			<span>+添加行业资讯</span>
		</div>
		<div class="industryInformation_list" v-if="industryList.length>0&&showWrit">
			<div v-for="(item,index) in industryList" :key="index" class="industryInformation_list_item">
				<span class="industryInformation_item_mark">{{indexFormat(index*1)}}</span>
				<div class="industryInformation_item_left">
					<span class="industryInformation_item_title">
						{{item.title}}
					</span>
					<div class="industryInformation_item_content">
						<el-tooltip popper-class="tooltip_bg" class="item" effect="dark" :content="item.content"
							placement="top-start">
							<span>{{item.content}}</span>
						</el-tooltip>
					</div>
					<div class="industryInformation_item_bottom">
						<div class="flex_item">
							<div class="industryInformation_item_time">
								<span class="industryInformation_item_label">来源：</span>
								<span class="industryInformation_item_value">{{item.origin}}</span>
							</div>
							<div class="industryInformation_item_time">
								<span class="industryInformation_item_label">已阅读：</span>
								<span class="industryInformation_item_value">{{item.readNum}}</span>
							</div>
							<div class="industryInformation_item_time">
								<span class="industryInformation_item_label">网址链接：</span>
								<span @click="handleForm('link', item)"
									class="industryInformation_item_value item_value_green">{{item.link}}</span>
							</div>
						</div>
						<div style="margin-right: 0;" class="industryInformation_item_time">
							<span class="industryInformation_item_label">发布时间：</span>
							<span class="industryInformation_item_value">{{item.createTime}}</span>
						</div>
					</div>
				</div>
				<div class="industryInformation_item_right">
					<div @click="handleForm('edit', item)" class="industryInformation_item_handle">
						<img src="@/assets/images/common/edit.png">
						<span>编辑</span>
					</div>
					<div @click="handleForm('delete', item)" class="industryInformation_item_handle">
						<img src="@/assets/images/common/delete.png">
						<span>删除</span>
					</div>
				</div>
			</div>
		</div>
		<div v-if="industryList.length == 0&&showWrit" class="industryInformation_empty">
			<img src="@/assets/images/common/empty1.png">
			<span>暂无行业资讯</span>
		</div>
		<div v-if="industryList.length > 0" class="industryInformation_page">
			<el-pagination @current-change="currentChange" :current-page.sync="pageIndex" :page-size="pageSize"
				layout="total, prev, pager, next" :total="totalCount">
			</el-pagination>
		</div>
		<div class="industryInformation_dialog">
			<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeIndustryDialog" width="579px" :title="diaLogIndustryTitle"
				:visible.sync="diaLogIndustryVisible">
				<div class="handle_form">
					<el-form ref="industryDetail" :rules="industryRules" :model="industryDetail" label-width="90px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" prop="title" label="资讯标题:">
									<input v-model="industryDetail.title" placeholder="请输入标题名称"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" prop="origin" label="来源:">
									<input v-model="industryDetail.origin" placeholder="请输入来源"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" prop="link" label="网址链接:">
									<input v-model="industryDetail.link" placeholder="请输入网址链接"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="" prop="content" label="正文:">
									<textarea v-model="industryDetail.content" placeholder="请输入正文内容"
										class="handle_form_textarea"></textarea>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" prop="readNum" label="阅读量:">
									<input v-model="industryDetail.readNum" placeholder="请输入阅读量"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="closeIndustryDialog" class="handle_form_cancel">取消</span>
						<span v-preventReClick @click="submitIndustry" class="handle_form_confirm">确定</span>
					</div>
				</div>
			</el-dialog>
		</div>
		<div v-if="!showWrit" element-loading-spinner="el-icon-loading" element-loading-text="数据加载中~"
			v-loading.lock="!showWrit" class="industryInformation_empty">
		</div>
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege"
			:isOpen="messegeVisible" :messegeTip="messegeTip" :messegeContent="messegeContent"
			:messegeType="messegeType" />
	</div>
</template>
<script>
	import {
		getIndustryInformation,
		addIndustryInformation,
		detailIndustryInformation,
		editIndustryInformation,
		deleteIndustryInformation
	} from "@/api/industryInformation";
	export default {
		data() {
			const checkreadNum = (rule, value, callback) => {
				let regxNum = /^[0-9]*$/;
				if (!value) {
					callback(new Error("请输入阅读量"));
				} else {
					if (!regxNum.test(value)) {
						callback(new Error("请输入正确阅读量"));
					} else {
						callback();
					}
				}
				callback();
			};
			return {
				pageIndex: 1,
				pageSize: 4,
				totalCount: 0,
				industryList: [],
				diaLogIndustryTitle: "",
				diaLogIndustryVisible: false,
				industryRules: {
					title: [{
						required: true,
						message: '请输入标题名称',
						trigger: 'change'
					}],
					origin: [{
						required: true,
						message: '请输入来源',
						trigger: 'change'
					}],
					link: [{
						required: true,
						message: '请输入网址链接',
						trigger: 'change'
					}],
					content: [{
						required: true,
						message: '请输入正文内容',
						trigger: 'change'
					}],
					readNum: [{
						required: true,
						// message: '请输入阅读量',
						validator: checkreadNum,
						trigger: 'change'
					}],
				},
				industryDetail: {},
				messegeVisible: false,
				messegeTip: "",
				messegeContent: "",
				messegeType: "",
				showInformation:true
			}
		},
		watch: {
			diaLogIndustryVisible: {
				handler(val) {
					if (!val) {
						this.$nextTick(() => {
							this.$refs.industryDetail.resetFields()
							this.$refs.industryDetail.clearValidate()
						})
					}
				},
				deep: true
			}
		},
		computed: {
			showWrit() {
				if (!this.showInformation) {
					return false
				} else {
					return true
				}
			}
		},
		created() {
			console.log("行业资讯", this.$route)
			this.industryInformationList()
		},
		methods: {
			// 序号处理
			indexFormat(index) {
				const page = this.pageIndex;
				const pageSize = this.pageSize;
				let resultIndex = index + 1 + (page - 1) * pageSize
				if (resultIndex < 10) {
					resultIndex = '0' + resultIndex
				}
				return resultIndex
			},
			// 获取资讯列表
			industryInformationList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				this.showInformation = false
				getIndustryInformation(params).then((res) => {
					this.industryList = res.data
					this.totalCount = res.page.totalCount * 1
					this.showInformation = true
				})
			},
			// 资讯分页
			currentChange(val) {
				this.pageIndex = val
				this.industryInformationList()
			},
			// 获取资讯详情
			industryInformationDetail(id) {
				detailIndustryInformation(id).then((res) => {
					if (res.code == 0) {
						this.industryDetail = res.data
					}
				})
			},
			// 行业资讯删除
			industryInformationDelete(id) {
				deleteIndustryInformation(id).then((res) => {
					if (res.code == 0) {
						this.$message({
							message: "行业资讯删除成功~",
							type: 'success'
						})
						this.messegeVisible = false;
						this.pageIndex = 1;
						this.industryInformationList()
					}
				})
			},
			closeIndustryDialog() {
				this.diaLogIndustryVisible = false;
			},
			submitIndustry() {
				this.$refs["industryDetail"].validate((valid) => {
					if (valid) {
						let params = JSON.parse(JSON.stringify(this.industryDetail))
						console.log("资讯数据>>>>", params)
						if (this.diaLogIndustryTitle == "添加行业资讯") {
							addIndustryInformation(params).then((res) => {
								if (res.code == 0) {
									this.$message({
										message: "行业资讯添加成功~",
										type: 'success'
									})
									this.diaLogIndustryVisible = false;
									this.industryInformationList()
								}
							})
						} else {
							editIndustryInformation(this.industryDetail.id, params).then((res) => {
								if (res.code == 0) {
									this.$message({
										message: "行业资讯编辑成功~",
										type: 'success'
									})
									this.diaLogIndustryVisible = false;
									this.industryInformationList()
								}
							})
						}
					}
				});
			},
			confirmMessege() {
				this.industryInformationDelete(this.industryDetail.id)
			},
			closeMessege() {
				this.messegeVisible = false;
			},
			handleForm(type, item) {
				switch (type) {
					case "add":
						this.industryDetail = {};
						this.diaLogIndustryTitle = "添加行业资讯";
						this.diaLogIndustryVisible = true;
						break;
					case "edit":
						this.industryInformationDetail(item.id)
						this.diaLogIndustryTitle = "编辑行业资讯";
						this.diaLogIndustryVisible = true;
						break;
					case "delete":
						this.industryDetail.id = item.id;
						this.messegeTip = "删除行业资讯";
						this.messegeContent = "确定删除此行业资讯吗?";
						this.messegeVisible = true;
						this.messegeType = "warn";
						break;
					case "link":
						if (item.link.includes("https://") || item.link.includes("http://")) {
							window.open(`${item.link}`)
						} else {
							window.open(`https://${item.link}`)
						}
						break;
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	@import "../../styles/index.scss";

	.flex_item {
		display: flex;
		align-items: center;
	}

	.industryInformation_empty {
		height: 500px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 100px;
		img {
			width: 300px;
			height: 250px;
			margin-bottom:20px;
		}
		span {
			font-size: 18px;
			color: #999999;
		}
	}
	.industryInformation {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		margin-top: 4px;
		padding: 20px 40px;
		box-sizing: border-box;
		border-top: 20px solid #F7F7F7;

		.industryInformation_btn {
			line-height: 36px;
			border: 1px solid $theme_color;
			border-radius: 2px;
			text-align: center;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: $theme_color;
			cursor: pointer;

		}

		.industryInformation_list {
			margin-top: 17px;
			border: 1px solid #EEEEEE;
			display: flex;
			flex-direction: column;

			

			.industryInformation_list_item {
				height: 162px;
				background: rgba(230, 16, 75, 0);
				padding: 26px 0 26px 40px;
				box-sizing: border-box;
				position: relative;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #EEEEEE;

				.industryInformation_item_mark {
					position: absolute;
					left: 12px;
					top: 11px;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: #333333;
				}

				.industryInformation_item_left {
					flex: 1;
					display: flex;
					flex-direction: column;
					padding-right: 70px;
					border-right: 1px solid #EEEEEE;

					.industryInformation_item_title {
						font-size: 16px;
						font-family: Source Han Sans CN;
						font-weight: bold;
						line-height: 24px;
						color: #333333;
						margin-bottom: 17px;
					}

					.industryInformation_item_content {
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 20px;
						color: #666666;
						overflow: hidden;
						cursor: pointer;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
					}

					.industryInformation_item_bottom {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: 14px;

						.industryInformation_item_time {
							display: flex;
							align-items: center;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 20px;
							margin-right: 80px;

							.industryInformation_item_label {
								color: #999999;
							}

							.industryInformation_item_value {
								color: #333333;
							}

							.item_value_green {
								color: $theme_color;
								cursor: pointer;
							}
						}
					}
				}

				.industryInformation_item_right {
					width: 180px;
					display: flex;
					align-items: center;
					justify-content: center;

					.industryInformation_item_handle {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						margin: 0 15px;
						cursor: pointer;

						img {
							width: 16px;
							height: 16px;
							margin-bottom: 8px;
						}

						span {
							font-size: 16px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 24px;
							color: #999999;
						}
					}
				}
			}
		}

		.industryInformation_list :last-child.industryInformation_list_item {
			border-bottom: 0;
		}

		.industryInformation_page {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 28px;
		}

		.industryInformation_dialog {
			.handle_form {
				.handle_form_row {
					display: flex;
					margin-top: 25px;

					.handle_form_item {
						flex: 1;
						display: flex;
						position: relative;
						margin-right: 20px;

						.handle_form_select {
							width: 407px;
						}

						.handle_form_ipt {
							width: 407px;
							line-height: 36px;
							border: 1px solid #EEEEEE;
							outline: none;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #000000;
							padding: 0 10px;
							box-sizing: border-box;
						}

						.handle_form_textarea {
							width: 407px;
							height: 120px;
							background: #FFFFFF;
							border: 1px solid #EEEEEE;
							border-radius: 4px;
							padding: 10px 10px;
							outline: none;
							resize: none;
							box-sizing: border-box;
						}
					}
				}

				.handle_form_btn {
					display: flex;
					align-items: center;
					margin: 40px 0 0px 0;

					.handle_form_cancel {
						margin-left: auto;
						width: 76px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid $theme_color;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: $theme_color;
						text-align: center;
						cursor: pointer;
						margin-right: 16px;
					}

					.handle_form_confirm {
						width: 76px;
						line-height: 34px;
						background: $theme_color;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFF;
						text-align: center;
						cursor: pointer;
					}
				}
			}
		}
	}
</style>
