import axios from '@/utils/request'

const Api = {
	industryInformationList: "/api/backyard/news/list",
	industryInformationAdd: "/api/backyard/news/",
	industryInformationDetail: "/api/backyard/news/",
	industryInformationEdit: "/api/backyard/news/",
	industryInformationDelete: "/api/backyard/news/",
}
// 资讯列表
export function getIndustryInformation (params) {
  return axios({
    method: 'get',
    url: Api.industryInformationList,
    params: params
  })
}

// 资讯添加
export function addIndustryInformation (params) {
  return axios({
    method: 'post',
    url: Api.industryInformationAdd,
    data: params
  })
}

// 资讯详情
export function detailIndustryInformation (params) {
  return axios({
    method: 'get',
    url: Api.industryInformationDetail + params
  })
}

// 资讯编辑
export function editIndustryInformation (id, params) {
  return axios({
    method: 'put',
    url: Api.industryInformationEdit + id,
    data: params
  })
}

// 资讯删除
export function deleteIndustryInformation (id) {
  return axios({
    method: 'delete',
    url: Api.industryInformationDelete + id
  })
}
